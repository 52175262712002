<template>
    <div class="d-flex align-center justify-space-between">
        <v-btn-toggle
            v-model="toggle_item"
            class="mr-2"
            dense
            elevation="2"
            @change="changeHour"
        >
            <v-btn
                v-for="button in toggleItems"
                height="30"
                dense
                :key="button.value"
                :value="button.value"
                >{{ button.text }}
            </v-btn>
        </v-btn-toggle>
        <v-menu v-model="menu" :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ on, attrs }">
                <div
                    v-bind="attrs"
                    v-on="on"
                    style="
                        height: 30px;
                        border: 1px solid rgba(0, 0, 0, 0.12);
                        padding: 4px;
                        display: flex;
                        align-items: center;
                        background: #f5f5f5;
                        border-radius: 4px;
                    "
                >
                    <v-icon dense class="mr-1">mdi-calendar-outline</v-icon>
                    <span style="transition: 2.5s">{{ currentDate }}</span>
                </div>
            </template>
            <v-date-picker
                v-model="date_item"
                type="month"
                @change="changeMonth"
            ></v-date-picker>
        </v-menu>
    </div>
</template>

<script>
import moment from "moment";
export default {
    name: "DateTimeFilter",
    data() {
        return {
            menu: false,
            toggle_item: "",
            date_item: "",
            toggleItems: [
                {
                    text: "3h",
                    value: "3",
                },
                {
                    text: "12h",
                    value: "12",
                },
                {
                    text: "24h",
                    value: "24",
                },
                {
                    text: "7d",
                    value: "7",
                },
            ],
        };
    },
    computed: {
        currentDate() {
            return moment(this.date_item).format("MMMM YYYY");
        },
    },
    methods: {
        changeMonth(e) {
            this.$emit("changeMonthFilter", e);
            this.toggle_item = null;
        },
        changeHour(e) {
            if (this.toggle_item) {
                this.$emit("changeHourFilter", e);
                this.date_item = moment().format("YYYY-MM");
            } else {
                this.changeMonth(this.date_item);
            }
        },
    },
    mounted() {
        this.date_item = moment().format("YYYY-MM");
    },
};
</script>

<style lang="scss">
.custom-btn-toggle {
}
</style>
