<template>
    <LineChartGenerator
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
    />
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs/legacy";

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
} from "chart.js";

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
    Filler
);

export default {
    name: "LineChart",
    components: {
        LineChartGenerator,
    },
    props: {
        chartId: {
            type: String,
            default: "line-chart",
        },
        datasetIdKey: {
            type: String,
            default: "label",
        },
        width: {
            type: Number,
            default: 400,
        },
        height: {
            type: Number,
            default: 400,
        },
        cssClasses: {
            default: "",
            type: String,
        },
        styles: {
            type: Object,
            default: () => {},
        },
        labels: {
            type: Array,
            default: () => [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ],
        },
        datasets: {
            type: Array,
            default: () => [
                {
                    label: "Data One",
                    data: [20, 40, 30, 50, 40, 60, 50, 70, 60, 80, 70, 90],
                    borderColor: "#4200FF",
                    backgroundColor: "#4200FF",
                    pointBorderWidth: "5",
                    pointBackgroundColor: "#4200FF",
                    pointBorderColor: "rgba(151, 151, 151, 0.500)",
                    borderWidth: "2",
                },
            ],
        },
        plugins: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            chartData: {
                labels: this.labels,
                datasets: this.datasets,

                // {
                //     label: "Data One",
                //     data: [20, 40, 30, 50, 40, 60, 50, 70, 60, 80, 70, 90],
                //     borderColor: "#4200FF",
                //     backgroundColor: "#4200FF",
                //     pointBorderWidth: "5",
                //     pointBackgroundColor: "#4200FF",
                //     pointBorderColor: "rgba(151, 151, 151, 0.500)",
                //     borderWidth: "2",
                //     // backgroundColor: this.backgroundColors,
                //     // data: this.data,
                // },
                // {
                //     label: "Data Two",
                //     data: [10, 30, 20, 40, 30, 50, 40, 60, 50, 70, 60, 80],
                //     borderColor: "#B724FD",
                //     backgroundColor: "#B724FD",
                //     pointBorderWidth: "5",
                //     pointBackgroundColor: "#B724FD",
                //     pointBorderColor: "rgba(151, 151, 151, 0.500)",
                //     borderWidth: "2",
                // backgroundColor: this.backgroundColors,
                // data: this.data,
                // },
                // {
                //     label: "Data Three",
                //     backgroundColor: "#36F097",
                //     data: [120, 30, 100, 200, 120, 80, 140, 160, 150, 170, 260, 180],
                //     fill: true,
                //     borderColor: "#36F097",
                //     // backgroundColor: this.backgroundColors,
                //     // data: this.data,
                //     // cubicInterpolationMode: 'monotone',
                // },
                // {
                //     label: "Data Four",
                //     backgroundColor: "#a13920",
                //     data: [10, 30, 20, 40, 30, 50, 40, 60, 50, 70, 60, 80],
                //     fill: false,
                //     borderColor: "#a13920",
                //     // backgroundColor: this.backgroundColors,
                //     // data: this.data,
                // },
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: this.plugins,
                scales: {
                    x: {
                        grid: {
                            display: false,
                        },
                    },
                    y: {
                        grid: {
                            display: false,
                        },
                    },
                },
            },
        };
    },
};
</script>
