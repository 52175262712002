<template>
    <div>
        <v-container
            id="dashboard"
            fluid
            class="fill-height flex-column flex-nowrap align-start"
        >
            <v-container fluid class="pt-5 px-5 mb-10">
                <v-row justify="space-between">
                    <v-col cols="12" md="7" class="d-flex">
                        <v-toolbar-title
                            class="headline font-weight-bold accent--text"
                            >Reports and Analytics</v-toolbar-title
                        >
                        <v-select
                            v-model="selectedDashboardPage"
                            background-color="transparent"
                            item-text="text"
                            item-value="value"
                            :menu-props="{ top: false, offsetY: true }"
                            class="ma-0 pa-0 ml-10 ministry-pages"
                            :items="dashboardItems"
                            @change="changeView"
                            color="primaryTextColor"
                            :style="`max-width: 200px; font-size: 20px; font-weight: 800;`"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-container>

            <v-container
                fluid
                class="mt-n4 flex-grow-1 fill-height align-start justify-center px-6"
            >
                <!-- <v-row>
                    <v-col cols="12" md="12" class="d-flex">
                        <v-card flat width="100%" class="pa-4 pb-6 card-shadow">
                            <v-card-title>All</v-card-title>
                            <v-row class="px-4">
                                <v-col cols="12" md="3">
                                    <v-card
                                        rounded
                                        flat
                                        class="custom-info-card px-1 pb-3"
                                    >
                                        <div class="upper-card-info">
                                            <v-card-title
                                                class="display-1 font-weight-bold"
                                                >0</v-card-title
                                            >
                                            <v-chip
                                                class=""
                                                label
                                                color="#D1FCE7"
                                            >
                                                <v-icon>mdi-trending-up</v-icon>
                                                <span class="ml-1">12.13%</span>
                                            </v-chip>
                                        </div>
                                        <span class="px-4 card-info-title"
                                            >Average Logins Per Day</span
                                        >
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-card
                                        rounded
                                        flat
                                        class="custom-info-card px-1 pb-3"
                                    >
                                        <div class="upper-card-info">
                                            <v-card-title
                                                class="display-1 font-weight-bold"
                                                >0</v-card-title
                                            >
                                            <v-chip
                                                class=""
                                                label
                                                color="#D1FCE7"
                                            >
                                                <v-icon>mdi-trending-up</v-icon>
                                                <span class="ml-1">12.13%</span>
                                            </v-chip>
                                        </div>
                                        <span class="px-4 card-info-title"
                                            >Average Session Length</span
                                        >
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12" class="px-4">
                                    <LineChart />
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row> -->
                <v-container
                    fluid
                    class="mt-n4 flex-grow-1 fill-height align-start justify-center pa-0 d-flex"
                >
                    <v-row>
                        <v-col>
                            <h2>Watch</h2>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8" md="8">
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-card
                                        flat
                                        width="100%"
                                        class="pa-4 pb-6 card-shadow"
                                    >
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                md="4"
                                                sm="4"
                                                xs="4"
                                            >
                                                <v-card-title
                                                    class="font-size-24"
                                                    style="font-weight: bold"
                                                    >Total Views</v-card-title
                                                >
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                md="8"
                                                sm="8"
                                                xs="8"
                                                style="
                                                    display: flex;
                                                    justify-content: flex-end;
                                                    align-items: center;
                                                "
                                                class="pr-8"
                                            >
                                                <DateTimeFilter
                                                    @changeMonthFilter="
                                                        changeTotalViewsMonth
                                                    "
                                                    @changeHourFilter="
                                                        changeTotalViewsHour
                                                    "
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row class="px-4">
                                            <v-col
                                                cols="12"
                                                md="8"
                                                sm="8"
                                                xs="8"
                                            >
                                                <v-card
                                                    v-if="
                                                        !loadingDataSets.totalViews &&
                                                        checkDataValid(
                                                            totalVideoViews
                                                                .data[0].data
                                                        )
                                                    "
                                                    rounded
                                                    flat
                                                    class="custom-info-card px-1 pb-3"
                                                >
                                                    <div
                                                        class="upper-card-info"
                                                    >
                                                        <v-card-title
                                                            class="display-1 font-weight-bold"
                                                            >{{
                                                                totalViews
                                                            }}</v-card-title
                                                        >
                                                        <v-chip
                                                            class=""
                                                            label
                                                            color="#D1FCE7"
                                                        >
                                                            <v-icon
                                                                >mdi-trending-up</v-icon
                                                            >
                                                            <span class="ml-1"
                                                                >12.13%</span
                                                            >
                                                        </v-chip>
                                                    </div>
                                                    <span
                                                        class="px-4 card-info-title"
                                                        >Total Views</span
                                                    >
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                        <v-row class="py-4 text-center">
                                            <v-col cols="12" md="12">
                                                <div
                                                    class="d-flex justify-center align-center"
                                                    v-if="
                                                        loadingDataSets.totalViews
                                                    "
                                                >
                                                    <v-progress-circular
                                                        indeterminate
                                                        color="primary"
                                                    ></v-progress-circular>
                                                </div>
                                                <LineChart
                                                    v-if="
                                                        !loadingDataSets.totalViews &&
                                                        checkDataValid(
                                                            totalVideoViews
                                                                .data[0].data
                                                        )
                                                    "
                                                    :datasets="
                                                        totalVideoViews.data
                                                    "
                                                    :labels="
                                                        totalVideoViews.labels
                                                    "
                                                    :plugins="
                                                        totalVideoViews.plugins
                                                    "
                                                />
                                                <span
                                                    v-if="
                                                        !loadingDataSets.totalViews &&
                                                        !checkDataValid(
                                                            totalVideoViews
                                                                .data[0].data
                                                        )
                                                    "
                                                    >No changes for this
                                                    filter.</span
                                                >
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" md="6">
                                    <v-card
                                        flat
                                        width="100%"
                                        class="pa-4 pb-6 card-shadow"
                                    >
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                md="12"
                                                sm="12"
                                                xs="12"
                                            >
                                                <v-card-title
                                                    class="font-size-24"
                                                    style="font-weight: bold"
                                                    >Average View
                                                    Duration</v-card-title
                                                >
                                            </v-col>
                                        </v-row>
                                        <v-row class="py-0">
                                            <v-col
                                                cols="12"
                                                md="12"
                                                sm="12"
                                                xs="12"
                                                style="
                                                    display: flex;
                                                    justify-content: flex-start;
                                                    margin-top: -1em;
                                                "
                                                class="pl-7 py-0"
                                            >
                                                <DateTimeFilter
                                                    @changeMonthFilter="() => {}"
                                                    @changeHourFilter="() => {}"
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row class="py-4 px-8 text-center">
                                            <v-col cols="12" md="12">
                                                <div
                                                    class="d-flex justify-center align-center"
                                                >
                                                    <!-- <v-progress-circular
                                                        indeterminate
                                                        color="primary"
                                                    ></v-progress-circular> -->
                                                </div>
                                                <DoughnutChart
                                                    :plugins="
                                                        totalVideoViews.plugins
                                                    "
                                                />
                                                <!-- <span
                                                    >No changes for this
                                                    filter.</span
                                                > -->
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                                <v-col cols="6" md="6">
                                    <v-card
                                        flat
                                        width="100%"
                                        class="pa-4 pb-6 card-shadow"
                                    >
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                md="12"
                                                sm="12"
                                                xs="12"
                                            >
                                                <v-card-title
                                                    class="font-size-24"
                                                    style="font-weight: bold"
                                                >
                                                    Watch Time</v-card-title
                                                >
                                            </v-col>
                                        </v-row>
                                        <v-row class="py-0">
                                            <v-col
                                                cols="12"
                                                md="12"
                                                sm="12"
                                                xs="12"
                                                style="
                                                    display: flex;
                                                    justify-content: flex-start;
                                                    margin-top: -1em;
                                                "
                                                class="pl-7 py-0"
                                            >
                                                <DateTimeFilter
                                                    @changeMonthFilter="() => {}"
                                                    @changeHourFilter="() => {}"
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row class="py-4 px-8 text-center">
                                            <v-col cols="12" md="12">
                                                <div
                                                    class="d-flex justify-center align-center"
                                                >
                                                    <!-- <v-progress-circular
                                                        indeterminate
                                                        color="primary"
                                                    ></v-progress-circular> -->
                                                </div>
                                                <DoughnutChart
                                                    :plugins="
                                                        totalVideoViews.plugins
                                                    "
                                                />
                                                <!-- <span
                                                    >No changes for this
                                                    filter.</span
                                                > -->
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="4" md="4">
                            <v-card
                                flat
                                width="100%"
                                class="pa-4 pb-6 card-shadow"
                                style="min-height: 100% !important"
                            >
                                <v-row>
                                    <v-col cols="12" md="12" sm="12" xs="12">
                                        <v-card-title
                                            class="font-size-24"
                                            style="font-weight: bold"
                                            >Most Viewed Videos</v-card-title
                                        >
                                    </v-col>
                                </v-row>
                                <v-row class="py-0">
                                    <v-col
                                        cols="12"
                                        md="12"
                                        sm="12"
                                        xs="12"
                                        style="
                                            display: flex;
                                            justify-content: flex-start;
                                            margin-top: -1em;
                                        "
                                        class="pl-7 py-0"
                                    >
                                        <DateTimeFilter
                                            @changeMonthFilter="() => {}"
                                            @changeHourFilter="() => {}"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row class="py-4 px-8 text-center">
                                    <v-col cols="12" md="12">
                                        <div
                                            class="d-flex justify-center align-center"
                                        >
                                            <!-- <v-progress-circular
                                                indeterminate
                                                color="primary"
                                            ></v-progress-circular> -->
                                        </div>
                                        <BarListItem v-for="item in 10" :key="item"/>
                                        <!-- <span>No changes for this filter.</span> -->
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>

                <v-container
                    fluid
                    class="mt-4 flex-grow-1 fill-height align-start justify-center pa-0 d-flex"
                >
                    <v-row>
                        <v-col>
                            <h2>Learn</h2>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8" md="8">
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-card
                                        flat
                                        width="100%"
                                        class="pa-4 pb-6 card-shadow"
                                    >
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                md="4"
                                                sm="4"
                                                xs="4"
                                            >
                                                <v-card-title
                                                    class="font-size-24"
                                                    style="font-weight: bold"
                                                    >Total Reads</v-card-title
                                                >
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                md="8"
                                                sm="8"
                                                xs="8"
                                                style="
                                                    display: flex;
                                                    justify-content: flex-end;
                                                    align-items: center;
                                                "
                                                class="pr-8"
                                            >
                                                <DateTimeFilter
                                                    @changeMonthFilter="
                                                        changeReadsMonth
                                                    "
                                                    @changeHourFilter="
                                                        changeReadsHour
                                                    "
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row class="px-4">
                                            <v-col
                                                cols="12"
                                                md="8"
                                                sm="8"
                                                xs="8"
                                            >
                                                <v-card
                                                    v-if="
                                                        !loadingDataSets.totalReads &&
                                                        checkDataValid(
                                                            totalRead?.data[0]
                                                                .data
                                                        )
                                                    "
                                                    rounded
                                                    flat
                                                    class="custom-info-card px-1 pb-3"
                                                >
                                                    <div
                                                        class="upper-card-info"
                                                    >
                                                        <v-card-title
                                                            class="display-1 font-weight-bold"
                                                            >{{
                                                                totalReads
                                                            }}</v-card-title
                                                        >
                                                        <v-chip
                                                            class=""
                                                            label
                                                            color="#D1FCE7"
                                                        >
                                                            <v-icon
                                                                >mdi-trending-up</v-icon
                                                            >
                                                            <span class="ml-1"
                                                                >12.13%</span
                                                            >
                                                        </v-chip>
                                                    </div>
                                                    <span
                                                        class="px-4 card-info-title"
                                                        >Total Reads</span
                                                    >
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                        <v-row class="py-4 text-center">
                                            <v-col cols="12" md="12">
                                                <div
                                                    class="d-flex justify-center align-center"
                                                    v-if="
                                                        loadingDataSets.totalReads
                                                    "
                                                >
                                                    <v-progress-circular
                                                        indeterminate
                                                        color="primary"
                                                    ></v-progress-circular>
                                                </div>
                                                <LineChart
                                                    v-if="
                                                        !loadingDataSets.totalReads &&
                                                        checkDataValid(
                                                            totalRead?.data[0]
                                                                .data
                                                        )
                                                    "
                                                    :datasets="totalRead.data"
                                                    :labels="totalRead.labels"
                                                    :plugins="totalRead.plugins"
                                                />
                                                <span
                                                    v-if="
                                                        !loadingDataSets.totalReads &&
                                                        !checkDataValid(
                                                            totalRead?.data[0]
                                                                .data
                                                        )
                                                    "
                                                    >No changes for this
                                                    filter.</span
                                                >
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" md="6">
                                    <v-card
                                        flat
                                        width="100%"
                                        class="pa-4 pb-6 card-shadow"
                                    >
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                md="12"
                                                sm="12"
                                                xs="12"
                                            >
                                                <v-card-title
                                                    class="font-size-24"
                                                    style="font-weight: bold"
                                                    >Average Read
                                                    Duration</v-card-title
                                                >
                                            </v-col>
                                        </v-row>
                                        <v-row class="py-0">
                                            <v-col
                                                cols="12"
                                                md="12"
                                                sm="12"
                                                xs="12"
                                                style="
                                                    display: flex;
                                                    justify-content: flex-start;
                                                    margin-top: -1em;
                                                "
                                                class="pl-7 py-0"
                                            >
                                                <DateTimeFilter
                                                    @changeMonthFilter="() => {}"
                                                    @changeHourFilter="() => {}"
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row class="py-4 px-8 text-center">
                                            <v-col cols="12" md="12">
                                                <div
                                                    class="d-flex justify-center align-center"
                                                >
                                                    <!-- <v-progress-circular
                                                        indeterminate
                                                        color="primary"
                                                    ></v-progress-circular> -->
                                                </div>
                                                <DoughnutChart
                                                    :plugins="
                                                        totalVideoViews.plugins
                                                    "
                                                />
                                                <!-- <span
                                                    >No changes for this
                                                    filter.</span
                                                > -->
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                                <v-col cols="6" md="6">
                                    <v-card
                                        flat
                                        width="100%"
                                        class="pa-4 pb-6 card-shadow"
                                    >
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                md="12"
                                                sm="12"
                                                xs="12"
                                            >
                                                <v-card-title
                                                    class="font-size-24"
                                                    style="font-weight: bold"
                                                >
                                                    Read Time</v-card-title
                                                >
                                            </v-col>
                                        </v-row>
                                        <v-row class="py-0">
                                            <v-col
                                                cols="12"
                                                md="12"
                                                sm="12"
                                                xs="12"
                                                style="
                                                    display: flex;
                                                    justify-content: flex-start;
                                                    margin-top: -1em;
                                                "
                                                class="pl-7 py-0"
                                            >
                                                <DateTimeFilter
                                                    @changeMonthFilter="() => {}"
                                                    @changeHourFilter="() => {}"
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row class="py-4 px-8 text-center">
                                            <v-col cols="12" md="12">
                                                <div
                                                    class="d-flex justify-center align-center"
                                                >
                                                    <!-- <v-progress-circular
                                                        indeterminate
                                                        color="primary"
                                                    ></v-progress-circular> -->
                                                </div>
                                                <DoughnutChart
                                                    :plugins="
                                                        totalVideoViews.plugins
                                                    "
                                                />
                                                <!-- <span
                                                    >No changes for this
                                                    filter.</span
                                                > -->
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="4" md="4">
                            <v-card
                                flat
                                width="100%"
                                class="pa-4 pb-6 card-shadow"
                                style="min-height: 100% !important"
                            >
                                <v-row>
                                    <v-col cols="12" md="12" sm="12" xs="12">
                                        <v-card-title
                                            class="font-size-24"
                                            style="font-weight: bold"
                                            >Most Read Materials</v-card-title
                                        >
                                    </v-col>
                                </v-row>
                                <v-row class="py-0">
                                    <v-col
                                        cols="12"
                                        md="12"
                                        sm="12"
                                        xs="12"
                                        style="
                                            display: flex;
                                            justify-content: flex-start;
                                            margin-top: -1em;
                                        "
                                        class="pl-7 py-0"
                                    >
                                        <DateTimeFilter
                                            @changeMonthFilter="() => {}"
                                            @changeHourFilter="() => {}"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row class="py-4 px-8 text-center">
                                    <v-col cols="12" md="12">
                                        <div
                                            class="d-flex justify-center align-center"
                                        >
                                            <!-- <v-progress-circular
                                                indeterminate
                                                color="primary"
                                            ></v-progress-circular> -->
                                        </div>
                                        <BarListItem v-for="item in 10" :key="item"/>
                                        <!-- <span>No changes for this filter.</span> -->
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
                <v-container
                    fluid
                    class="mt-4 flex-grow-1 fill-height align-start justify-center pa-0 d-flex"
                >
                    <v-row>
                        <v-col>
                            <h2>Prayers</h2>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="12" class="d-flex">
                            <v-card
                                flat
                                width="100%"
                                class="pa-4 pb-6 card-shadow"
                            >
                                <v-row>
                                    <v-col cols="12" md="4" sm="4" xs="4">
                                        <v-card-title
                                            class="font-size-24"
                                            style="font-weight: bold"
                                            >Prayers</v-card-title
                                        >
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="8"
                                        sm="8"
                                        xs="8"
                                        style="
                                            display: flex;
                                            justify-content: flex-end;
                                            align-items: center;
                                        "
                                        class="pr-8"
                                    >
                                        <DateTimeFilter
                                            @changeMonthFilter="() => {}"
                                            @changeHourFilter="() => {}"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row class="py-4 px-8 text-center">
                                    <v-col cols="12" md="12">
                                        <div
                                            class="d-flex justify-center align-center"
                                        >
                                            <!-- <v-progress-circular
                                                indeterminate
                                                color="primary"
                                            ></v-progress-circular> -->
                                        </div>
                                        <LineChart
                                            :datasets="[
                                                {
                                                    label: 'Prayers Submitted',
                                                    data: [
                                                        20, 40, 30, 50, 40, 60,
                                                        50, 70, 60, 80, 70, 90,
                                                    ],
                                                    borderColor: '#36F097',
                                                    backgroundColor: '#36F097',
                                                    pointBorderWidth: '5',
                                                    pointBackgroundColor:
                                                        '#36F097',
                                                    pointBorderColor:
                                                        'rgba(151, 151, 151, 0.500)',
                                                    borderWidth: '2',
                                                },
                                                {
                                                    label: 'Prayers prayed for',
                                                    data: [
                                                        40, 30, 20, 70, 80, 90,
                                                        70, 60, 40, 50, 30, 40,
                                                    ],
                                                    borderColor: '#FD2424',
                                                    backgroundColor: '#FD2424',
                                                    pointBorderWidth: '5',
                                                    pointBackgroundColor:
                                                        '#FD2424',
                                                    pointBorderColor:
                                                        'rgba(151, 151, 151, 0.500)',
                                                    borderWidth: '2',
                                                },
                                            ]"
                                        />
                                        <!-- <span>No changes for this filter.</span> -->
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-container>
        </v-container>
    </div>
</template>

<script>
import LineChart from "@/components/Charts/Line";
import DoughnutChart from "@/components/Charts/Doughnut";
import moment from "moment";
import { GET_TOTAL_VIDEO_VIEWS, GET_TOTAL_READ } from "@/store/modules/report";
import DateTimeFilter from "../components/DateTimeFilter.vue";
import BarListItem from "../components/BarListItem.vue";
export default {
    name: "dashboard-app-usage",

    components: {
        LineChart,
        DoughnutChart,
        DateTimeFilter,
        BarListItem,
    },

    data() {
        return {
            selectedDashboardPage: "/dashboard/app-usage",
            loadingDataSets: {
                totalViews: false,
                totalReads: false,
            },
            dashboardItems: [
                {
                    text: "Users",
                    value: "/dashboard/users",
                },
                {
                    text: "App Usage",
                    value: "/dashboard/app-usage",
                },
                {
                    text: "Session",
                    value: "/dashboard/session",
                },
            ],
            chartOptions: {
                legend: {
                    position: "top",
                },
            },

            totalVideoViews: {
                data: [],
                labels: [],
                plugins: {},
            },
            totalRead: {
                data: [],
                labels: [],
                plugins: {},
            },
        };
    },
    computed: {
        totalViews() {
            return this.totalVideoViews?.data[0]?.data.reduce(
                (prev, curr) => prev + curr,
                0
            );
        },
        totalReads() {
            return this.totalRead?.data[0]?.data.reduce(
                (prev, curr) => prev + curr,
                0
            );
        },
    },
    methods: {
        checkDataValid(data) {
            if (data.every((item) => item === 0)) return false;
            return true;
        },
        changeView(to) {
            this.$router.push(to);
        },
        changeTotalViewsMonth(e) {
            this.getTotalVideoViews({
                year: moment(e).format("YYYY"),
                month: moment(e).format("MM"),
            });
        },
        changeTotalViewsHour(e) {
            switch (e) {
                case "3":
                    return this.getTotalVideoViews({ last_3_hours: true });
                case "12":
                    return this.getTotalVideoViews({ last_12_hours: true });
                case "24":
                    return this.getTotalVideoViews({ last_24_hours: true });
                case "7":
                    return this.getTotalVideoViews({ last_7_days: true });
            }
        },
        changeReadsMonth(e) {
            this.getTotalReads({
                year: moment(e).format("YYYY"),
                month: moment(e).format("MM"),
            });
        },
        changeReadsHour(e) {
            switch (e) {
                case "3":
                    return this.getTotalReads({ last_3_hours: true });
                case "12":
                    return this.getTotalReads({ last_12_hours: true });
                case "24":
                    return this.getTotalReads({ last_24_hours: true });
                case "7":
                    return this.getTotalReads({ last_7_days: true });
            }
        },
        async getTotalReads(payload) {
            this.loadingDataSets.totalReads = true;
            const result = await this.$store.dispatch(GET_TOTAL_READ, payload);
            const demographics = result.data.data.data;
            this.totalRead.labels = demographics.map((item) => {
                let intervals = item.interval.split(" - ");
                if (
                    payload.last_3_hours ||
                    payload.last_12_hours ||
                    payload.last_24_hours
                ) {
                    return `${moment(intervals[0]).format("LT")}`;
                } else if (payload.last_7_days) {
                    return `${moment(intervals[0]).format("MM/DD")} - ${moment(
                        intervals[0]
                    ).format("ddd")}`;
                } else {
                    return `${moment(intervals[0]).format("MMM DD, YYYY")} `;
                }
            });
            this.totalRead.data = [
                {
                    data: demographics.map((item) => item.count),
                    borderColor: "#1ED6FF",
                    backgroundColor: (ctx) => {
                        const canvas = ctx.chart.ctx;
                        const gradient = canvas.createLinearGradient(
                            0,
                            0,
                            0,
                            400
                        );

                        gradient.addColorStop(0, "#1ED6FF");
                        gradient.addColorStop(1, "rgba(30, 214, 255, 0.2)");

                        return gradient;
                    },
                    pointBorderWidth: "5",
                    pointBackgroundColor: "#1ED6FF",
                    pointBorderColor: "#1ed6ff73",
                    borderWidth: "2",
                    fill: true,
                },
            ];
            this.totalRead.plugins = {
                legend: {
                    display: false,
                },
            };

            this.loadingDataSets.totalReads = false;
        },
        async getTotalVideoViews(payload) {
            // console.log("PAYLOAD", payload);
            this.loadingDataSets.totalViews = true;
            const result = await this.$store.dispatch(
                GET_TOTAL_VIDEO_VIEWS,
                payload
            );
            const demographics = result.data.data.data;
            // console.log("payload", payload);
            this.totalVideoViews.labels = demographics.map((item) => {
                let intervals = item.interval.split(" - ");
                if (
                    payload.last_3_hours ||
                    payload.last_12_hours ||
                    payload.last_24_hours
                ) {
                    return `${moment(intervals[0]).format("LT")}`;
                } else if (payload.last_7_days) {
                    return `${moment(intervals[0]).format("MM/DD")} - ${moment(
                        intervals[0]
                    ).format("ddd")}`;
                } else {
                    return `${moment(intervals[0]).format("MMM DD, YYYY")} `;
                }
            });
            this.totalVideoViews.data = [
                {
                    data: demographics.map((item) => item.count),
                    borderColor: "#1ED6FF",
                    backgroundColor: (ctx) => {
                        const canvas = ctx.chart.ctx;
                        const gradient = canvas.createLinearGradient(
                            0,
                            0,
                            0,
                            400
                        );

                        gradient.addColorStop(0, "#1ED6FF");
                        gradient.addColorStop(1, "rgba(30, 214, 255, 0.2)");

                        return gradient;
                    },
                    pointBorderWidth: "5",
                    pointBackgroundColor: "#1ED6FF",
                    pointBorderColor: "#1ed6ff73",
                    borderWidth: "2",
                    fill: true,
                },
            ];
            this.totalVideoViews.plugins = {
                legend: {
                    display: false,
                },
            };

            this.loadingDataSets.totalViews = false;
        },
    },
    async created() {
        this.getTotalVideoViews({
            year: moment().format("YYYY"),
            month: moment().format("MM"),
        });
        this.getTotalReads({
            year: moment().format("YYYY"),
            month: moment().format("MM"),
        });
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#dashboard {
}

.custom-info-card {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) !important;
    border-radius: 8px !important;
    max-width: 250px;
}

.card-shadow {
    box-shadow: 1px 1px 6px rgba(43, 53, 116, 0.08) !important;
    min-height: 500px !important;
}

.upper-card-info {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 15px 0 0;
}

.card-info-title {
    color: gray;
    font-size: 18px;
}
</style>
